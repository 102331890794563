import { Component } from 'vue-property-decorator'
import { LocalStorage } from 'quasar'
import { BaseMixin } from 'src/components/mixins/base'

@Component
export default class AgreeToMarketingCheckbox extends BaseMixin {
  agreeToMarketing = false

  onAgreeToMarketingChange () {
    LocalStorage.set('agreeToMarketing', this.agreeToMarketing)
  }
}
